import { 
  useMetamask, 
  useAddress, 
  useEditionDrop, 
  useToken,
  useVote,
  useNetwork } from '@thirdweb-dev/react';
import { useState, useEffect, useMemo } from "react";
import { AddressZero } from "@ethersproject/constants";
import { ChainId } from '@thirdweb-dev/sdk';

const App = () => {
  // use the hooks thridweb give us
  const address = useAddress();
  const network = useNetwork();
  const connectWithMetamask = useMetamask();
  console.log("Your Address: ", address);

  // Initialize our editionDrop contract (ERC-1155)
  const editionDrop = useEditionDrop("0x0Ffa0BD3eDaB5818f582B2f51EC4AeC5bf9dc579");
  // Initialize our token contract (ERC-20)
  const token = useToken("0xE9f6d55371d574Ed77778F4a13985b664E4388B1");
  // Initalize vote Contract (ERC-20 Vote)
  const vote = useVote("0x77ef715497aA927BF0ae07ca714590f5F1286ead");

  // State variable for us to know if user has our NFT
  const [hasClaimedNFT, setHasClaimedNFT] = useState(false);
  // isClaiming let us easily keep a loading state
  const [isClaiming, setIsclaiming] = useState(false);
  // Holds the amount of token each member has in state
  const [memberTokenAmounts, setMemberTokenAmounts] = useState([]);
  // The array holding all of our members addresses.
  const [memberAddresses, setMemberAddresses] = useState([]);

  // state for proposal and voting
  const [proposals, setProposals] = useState([]);
  const [isVoting, setIsVoting] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);

  // A fancy function to shorten someones wallet address, no need to show the whole thing.
  const shortenAddress = (str) => {
    return str.substring(0, 6) + "..." + str.substring(str.length - 4);
  };

  // Ambil semua proposal dari Smart Contract
  useEffect(() => {
    if(!hasClaimedNFT) {
      return;
    }

    // A simple call to vote.getAll() to Grab the proposals
    const getAllProposals = async () => {
      try {
        const proposals = await vote.getAll();
        setProposals(proposals);
        console.log("🌈 Proposals: ", proposals)
      } catch (error) {
        console.error("Failed to get proposals", error);
      }
    };

    getAllProposals();
  }, [hasClaimedNFT, vote]);

  // Cek apakah user udeh vote atau belum
  useEffect(() => {
    if(!hasClaimedNFT) return;

    // if we haven't finished retrieving the proposals from the useEffect above
    // then we can't check if the user voted yet!
    if(!proposals.length) {
      return;
    }

    const checkIfUserHasVoted = async () => {
      try {
        const hasVoted = await vote.hasVoted(proposals[0].proposalId, address);
        setHasVoted(hasVoted);
        if(hasVoted) {
          console.log("🥵 User has already voted");
        } else {
          console.log("🙂 User has not voted yet");
        }
      } catch (error) {
        console.error("failed to check if walled has voted", error);
      }
    };

    checkIfUserHasVoted();

  }, [hasClaimedNFT, proposals, address, vote]);

  // This useEffect grabs all the addresses of our members holding our NFT.
  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    // Tampilkan member yang nge-hold NFT
    const getAllAddressses = async () => {
      try {
        const memberAddresses = await editionDrop.history.getAllClaimerAddresses(0);
        setMemberAddresses(memberAddresses);
        console.log("Member yang hold NFT: ", memberAddresses);
      } catch (error) {
        console.error("Ada error bang: ", error);
      }
    };
    getAllAddressses();
  }, [hasClaimedNFT, editionDrop.history]);

  // UseEffect buat nampilin jumlah token yang dihold sama member DAO
  useEffect(() => {
    if (!hasClaimedNFT) {
      return;
    }

    const getAllBalances = async () => {
      try {
        const amounts = await token.history.getAllHolderBalances();
        setMemberTokenAmounts(amounts);
        console.log("👜 Amounts", amounts);
      } catch (error) {
        console.error("Ada error bang:", error)
      }
    };

    getAllBalances();
  }, [hasClaimedNFT, token.history]);

  // Now, we combine the memberAddresses and memberTokenAmounts into a single array
  const memberList = useMemo(() => {
    return memberAddresses.map((address) => {
      const member = memberTokenAmounts?.find(({ holder }) => holder === address);

      return {
        address,
        tokenAmount: member?.balance.displayValue || "0",
      }
    });
  }, [memberAddresses, memberTokenAmounts]);

  useEffect(() => {
    // jika wallet belum konek
    if (!address) {
      return;
    }

    const checkBalance = async () => {
      try {
        const balance = await editionDrop.balanceOf(address, 0);
        if (balance.gt(0)) {
          setHasClaimedNFT(true);
          console.log("🌟 this user has a membership NFT!");
        } else {
          setHasClaimedNFT(false);
          console.log("😭 this user doesn't have a membership NFT.");
        }
      } catch (error) {
        console.error("Ada error bang! ", error);
      }
    };

    checkBalance();
  }, [address, editionDrop]);

  const mintNft = async () => {
    try {
      setIsclaiming(true);
      await editionDrop.claim("0", 1);
      console.log(`🌊 Successfully Minted! Check it out on OpenSea: https://testnets.opensea.io/assets/${editionDrop.getAddress()}/0`);
      setHasClaimedNFT(true);
    } catch (error) {
      setHasClaimedNFT(false);
      console.log("Ada error nih bang: ", error);
    } finally {
      setIsclaiming(false);
    }
  };

  const handleVote = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // before we do async things, we want to disable the button to prevent doble click
    setIsVoting(true);

    // lets get the votes from the form for the values
    const votes = proposals.map((proposal) => {
      const voteResult = {
        proposalId: proposal.proposalId,
        // abstain by default
        vote: 2
      };

      proposal.votes.forEach(vote => {
        const elem = document.getElementById(
          proposal.proposalId + "-" + vote.type
        );

        if(elem.checked) {
          voteResult.vote = vote.type;
          return
        }
      });
      return voteResult;
    });

    // first we need to make sure the user delegates their token to vote
    try {
      //we'll check if the wallet still needs to delegate their tokens before they can vote
      const delegation = await token.getDelegationOf(address);
      // if the delegation is the 0x0 address that means they have not delegated their governance tokens yet
      if (delegation === AddressZero) {
        //if they haven't delegated their tokens yet, we'll have them delegate them before voting
        await token.delegateTo(address);
      }
      // then we need to vote on the proposals
      try {
        await Promise.all(
          votes.map(async ({ proposalId, vote: _vote }) => {
            // before voting we first need to check whether the proposal is open for voting
            // we first need to get the latest state of the proposal
            const proposal = await vote.get(proposalId);
            // then we check if the proposal is open for voting (state === 1 means it is open)
            if (proposal.state === 1) {
              // if it is open for voting, we'll vote on it
              return vote.vote(proposalId, _vote);
            }
            // if the proposal is not open for voting we just return nothing, letting us continue
            return;
          })
        );
        try {
          // if any of the propsals are ready to be executed we'll need to execute them
          // a proposal is ready to be executed if it is in state 4
          await Promise.all(
            votes.map(async ({ proposalId }) => {
              // we'll first get the latest state of the proposal again, since we may have just voted before
              const proposal = await vote.get(proposalId);

              //if the state is in state 4 (meaning that it is ready to be executed), we'll execute the proposal
              if (proposal.state === 4) {
                return vote.execute(proposalId);
              }
            })
          );
          // if we get here that means we successfully voted, so let's set the "hasVoted" state to true
          setHasVoted(true);
          // and log out a success message
          console.log("successfully voted");
        } catch (err) {
          console.error("failed to execute votes", err);
        }
      } catch (err) {
        console.error("failed to vote", err);
      }
    } catch (err) {
      console.error("failed to delegate tokens");
    } finally {
      // in *either* case we need to set the isVoting state to false to enable the button again
      setIsVoting(false);
    }
  }

  if (address && (network?.[0].data.chain.id !== ChainId.Rinkeby)) {
    return (
      <div className='unsupported-network'>
        <h2>Please connect to Rinkeby</h2>
        <p>This dapp only works on the Rinkeby Network, please which networks in your connected wallet.</p>
      </div>
    )
  }

  if (!address) {
    return (
      <div className='landing'>
        <h1>Welcome to Lombok Blockchain Society DAO</h1>
        <button onClick={connectWithMetamask}
          className="btn-hero">Connect your wallet</button>
      </div>
    )
  }

  // show DAO dashboard
  if (hasClaimedNFT) {
    return (
      <div className='member-page'>
        <img src="/lbs-logo.png" width={250} alt="LBS Logo"/>
        <h1>Lombok Blockchain DAO</h1>
        <p>Welcome <code>{ address }</code></p>
        <div>
          <div>
            <h2>Member List</h2>
            <table className='card'>
              <thead>
                <tr>
                  <th>Address</th>
                  <th>Token Amount</th>
                </tr>
              </thead>
              <tbody>
                {memberList.map((member) => {
                  return (
                    <tr key={member.address}>
                      <td>{shortenAddress(member.address)}</td>
                      <td>{member.tokenAmount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Proposal List */}
          <div id="proposals">
            <h2>Active Proposals</h2>
            <form onSubmit={handleVote}>
                {proposals.map((proposal) => (
                  <div key={proposal.proposalId} className="card">
                    <h5>{proposal.description}</h5>
                    <div>
                      {proposal.votes.map(({type, label}) => (
                        <div key={type}>
                          <input 
                            type="radio" 
                            id={proposal.proposalId + "-" + type}
                            name={proposal.proposalId}
                            value={type}
                            defaultChecked={type === 2} />
                            <label htmlFor={proposal.proposalId + "-" + type}>{label}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}

                <button disabled={isVoting}>{isVoting 
                ? "Voting..."
                : hasVoted
                  ? "You Already Voted"
                  : "Submit Votes" }
                </button>

                {!hasVoted && (
                  <small>This will trigger multiple transactions that you will need to sign</small>
                )}
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mint-nft">
      <h1>Yuk gabung jadi member DAO Lombok Blockchain Society</h1>
      <button
        disabled={isClaiming}
        onClick={mintNft}
      >
        {isClaiming ? "Minting..." : "Klaim NFT Membership"}
      </button>
    </div>
  );
};

export default App;
